html {
  height: 100%;
}

body {
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

#root {
  display: flex;
  flex: 1;
}

.link-unstyled,
.link-unstyled:visited,
.link-unstyled:hover,
.link-unstyled:active,
.link-unstyled:focus,
.link-unstyled:active:hover {
  font-style: inherit;
  color: inherit;
  background-color: transparent;
  font-size: inherit;
  text-decoration: none;
  font-variant: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
  border-radius: inherit;
  border: inherit;
  outline: inherit;
  box-shadow: inherit;
  padding: inherit;
  vertical-align: inherit;
}

@media (min-width:768px) {
  .border-md-right {
    border-right: 1px solid #dee2e6 !important;
  }
}

.article-content {
  font-size: 1.2em;
  max-width: 800px !important;
  margin-left: auto;
  margin-right: auto;
}

.article-content h2,
.article-content h3 {
  padding-top: 1em;
}

.Index-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.Index-section.Index-section-first {
  padding-top: 8rem;
}

.Index-section.Index-section-last {
  padding-bottom: 8rem;
}

.Index-section h2 {
  font-weight: 600;
}

.Index-section-alt-bg {
  background-color: #f8f9fa;
}

li > input[type="checkbox"] {
  margin-right: 0.5em;
  margin-bottom: 0.27em;
  vertical-align: bottom;
}
