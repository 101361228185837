#support_bubble {
  background-color: white;
  border-radius: 10px;
  padding: 0;
  right: 20px;
  bottom: 90px;
  position: fixed;
  z-index: 9998;

  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

#support_bubble {
  width: 16em;
  padding: 1em;

  text-align: center;
}

#support_bubble h3 {
  font-weight: bold;
  font-size: 160%;
}

#support_bubble p {
  font-weight: 500;
}

#support_bubble .btn {
  position: relative;
  margin-top: 0.5em;
}

#support_bubble .icon-left {
  position: absolute;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
}

#support_bubble .bubble_social {
  padding-top: 0.7em;
  font-size: 140%;
}

#support_button {
  background-color: #0091ea;
  color: #ffffff;
  display: block;
  cursor: pointer;
  right: 20px;
  bottom: 20px;
  position: fixed;
  z-index: 9997;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  padding: 5px;

  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}

#support_button:focus {
  outline: none;
}

#support_button:active {
  background-color: #3399ff;
}

#support_bubble > p {
  margin-top: 0;
  margin-bottom: 0;
}
